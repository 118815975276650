import { IIndexable } from '@/customtypes/common';

export const SD = 'SD';
export const BNB = 'BNB';
export const ETH = 'ETH';
export const POLYGON = 'POLYGON';
export const FANTOM = 'FANTOM';
export const HEDERA = 'HEDERA';

interface CalculatorToken {
  [key: string]: string;
}

export const SUPPORTED_CHAINS = [ETH, BNB, POLYGON, SD] as const;

export const REWARDSCALCULATORMAP: CalculatorToken = {
  SD,
  BNB,
  ETH,
  POLYGON: 'Matic',
  FANTOM: 'FTM',
  HEDERA: 'HBAR',
};

const getWithdrawalsDays = () => {
  const TOKEN = process.env.NEXT_PUBLIC_TOKEN;
  switch (TOKEN) {
    case ETH:
      return '7-10';
  }
  return '15';
};

export const STAKING_DISABLED_CHAINS = [FANTOM];

export const xTokenMapping: IIndexable = {
  ethx: 'ETHx',
  maticx: 'MaticX',
  bnbx: 'BNBx',
};

export const BINANCE_WALLET_IDS = ['binance', 'BinanceW3WSDK'];

export const STADER_URL = 'https://staderlabs.com/';
export const AUDIT_TOOLTIP_TEXT = 'Click to know more on audits';
export const TVL_TOOLTIP_TEXT = 'Total SD Staked';
export const TERMS_URL = 'https://staderlabs.com/terms-of-service/';
export const PRIVACY_POLICY_URL = 'https://staderlabs.com/privacy-policy/';
export const SUBSCRIPTIONS_URL = 'https://common.staderlabs.com/subscriptions';
export const TOAST_DURATION = 5000;
export const DAYS_TO_WAIT_FOR_WITHDRAW = getWithdrawalsDays();

export const getTransitionFee = (usdPrice: number, TOKEN: string) => {
  switch (TOKEN) {
    case HEDERA:
      return parseFloat((0.2 / usdPrice).toFixed(4));
    default:
      return null;
  }
};

export const TILL_DATE = '2024-05-31 16:00:00';

export const chainIconMapping = (chain: string) => {
  switch (chain) {
    case 'arbitrum':
      return '/assets/images/chains/arbitrum.svg';
    case 'optimism':
      return '/assets/images/chains/optimism.svg';
    case 'eth':
      return '/assets/images/chains/eth.svg';
    case 'xlayer':
      return '/assets/images/chains/xlayer-round.svg';
    case 'gnosis':
      return '/assets/images/chains/gnosis.svg';
  }
  return '/assets/images/chains/optimism.svg';
};

export const chainIdIconMapping: Record<string, string> = {
  '1': '/assets/images/chains/ethereum.svg',
  '10': '/assets/images/chains/optimism.svg',
  '42161': '/assets/images/chains/arbitrum.svg',
  '196': '/assets/images/chains/xlayer.svg',
};

export type BridgeData = {
  route_details: {
    route_from: string;
    route_to: string;
    token_from: string;
    token_to: string;
  };
  cta: Array<{
    title: string;
    link: string;
  }>;
};
